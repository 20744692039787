import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { content } from "./shared/routes/routes";
import { authGuard } from "./auth/login/authentication/guards/auth.guards";

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import("./auth/auth.module").then(m => m.AuthModule)
    },
    {
        path: "",
        component: ContentComponent,
        children: content,
        canActivate: [authGuard]
    },
    {
        path: "**",
        redirectTo: "/error",
    },
];

@NgModule({
    imports: [
        [
            RouterModule.forRoot(routes, {
                anchorScrolling: "enabled",
                scrollPositionRestoration: "enabled",
                useHash: true
            }),
        ],
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
