import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {Pageable} from "../../interfaces/Pageable/pageable";
import {UsuarioService} from "../usuario.service";
import {UsuarioHttpService} from "../../http-services/usuario-http.service";
import {EMPTY, Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable()
export class UsuarioResolver implements Resolve<Pageable> {

    constructor(
        private usuarioService: UsuarioService,
        private usuarioHttpService: UsuarioHttpService,
        private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<Pageable> | Promise<Pageable> | Pageable {
        return this.usuarioHttpService.getAll(this.usuarioService.state.filtro, this.usuarioService.state.data.number || 0).pipe(catchError(err => {
            this.router.navigate(["/error"]);
            return EMPTY;
        }));
    }
}
