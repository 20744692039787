import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[removeAccent]'
})
export class RemoveAccentDirective {

  @HostListener('input', ['$event'])
  onInputChange(event: InputEvent): void {

    const inputElement = event.target as HTMLInputElement | HTMLTextAreaElement;    
    const value = inputElement.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');    
    inputElement.value = value;

  }
}