import { MarkAsteriskDirective } from './asterisk-required.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumbersDirective } from './only-numbers.directive';
import { Overlay } from '@angular/cdk/overlay';
import { AutofocusDirective } from './autofocus-directive';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { RemoveAccentDirective } from './remove-accent.directive';



@NgModule({
  declarations: [OnlyNumbersDirective, AutofocusDirective, BlockCopyPasteDirective, MarkAsteriskDirective, RemoveAccentDirective],
  imports: [CommonModule],
  exports: [OnlyNumbersDirective, AutofocusDirective, BlockCopyPasteDirective, MarkAsteriskDirective, RemoveAccentDirective],
  providers: [
    Overlay,
  ]
})
export class DirectivesModule { }
