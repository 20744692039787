import { UsuarioHttpService } from '../../http-services/usuario-http.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UsuarioLogin } from '../../interfaces/usuario-login';

@Injectable()
export class UsuarioDetalhesResolver implements Resolve<UsuarioLogin> {

    constructor(
        private _usuarioHttpService: UsuarioHttpService,
        private _router: Router) { }


    resolve(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<UsuarioLogin> | Promise<UsuarioLogin> | UsuarioLogin {
        return this._usuarioHttpService.getById(route.params.id).pipe(catchError(err => {
            this._router.navigate(["/error"]);
            return EMPTY;
        }));
    }
}