import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../../app.service';
import { UsuarioHttpService } from '../../http-services/usuario-http.service';
import { UsuarioLogin } from '../../interfaces/usuario-login';
import { AddressService } from '../../utils/services/address.service';
import handleErrorMessage from '../../utils/services/error-message-handler';
import { MessageService } from '../../utils/services/message.service';
import { UtilsService } from '../../utils/services/utils-service';

@Component({
  selector: 'app-usuario-detalhes',
  templateUrl: './usuario-detalhes.component.html',
  styleUrl: './usuario-detalhes.component.scss'
})
export class UsuarioDetalhesComponent implements OnInit {

  public form: FormGroup
  public submitted = false;
  public show: boolean = false;
  private _subscription: Subscription
  estados: any[];
  usuario: UsuarioLogin;

  constructor(
    private _fb: FormBuilder,
    private _utilsService: UtilsService,
    private _addressService: AddressService,
    private _messageService: MessageService,
    private _appService: AppService,
    private _usuarioHttpService: UsuarioHttpService,
    private _location: Location,
    private _activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.estados = this._utilsService.loadBrazilStates();
    this.getById();
  }

  getById() {
    // get data from resolver;
    this._subscription = this._activatedRoute.data.subscribe(
      (result) => {
        this.usuario = result.usuario;
        this.form.patchValue(this.usuario);

      }
    )
  }

  /**
   * Método de inicialização dos formulários;
   */
  public initForm() {
    this.form = this._fb.group({
      usuarioId: [null],
      nome: ['', [Validators.required, Validators.minLength(3)]],
      numeroDocumento: ['', [Validators.required, Validators.minLength(11)]],
      email: ['', [Validators.required, Validators.email]],
      ddd: ['', Validators.required],
      telefone: ['', Validators.required],
      ativo: [true],
      endereco: this._fb.group({
        enderecoId: [null],
        cep: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
        rua: ['', [Validators.required]],
        numero: ['', [Validators.required]],
        complemento: [''],
        cidade: ['', [Validators.required]],
        bairro: ['', [Validators.required]],
        estado: ['', [Validators.required]],
        pais: ['Brasil'],
      })
    });
  }

  async submit() {

    this.form.markAllAsTouched();
    this.submitted = true;

    if (this.form.valid) {
      const usuario = this.form.value;

      const confirmation = await this._appService.fireSwalAsync('Atenção', 'Deseja Realmente salvar?', 'Salvar');

      if (confirmation) {
        this._usuarioHttpService.update(usuario).subscribe({
          next: (result) => {
            this._messageService.show('Atenção', 'Registro salvo com sucesso!', 'success');
          },
          error: (err) => {
            console.log(err);
            handleErrorMessage(this._messageService, err);
          }
        })
      }

    } else {
      this._messageService.show('Atenção', 'Preencha todos os Campos', 'warning');
    }
  }

  voltar() {
    this._location.back();
  }

  showPassword() {
    this.show = !this.show;
  }

  /**
 * Função para buscar o endereço a partir do CEP digitado;
 */
  onBlurCep() {
    this._addressService.getAddressByCep(this.enderecoForm.get('cep').value).subscribe(
      (result) => {

        if (result.erro != undefined && result.erro) {
          this._messageService.show('Atenção', 'CEP digitado está inválido ou não foi encontrado.', 'warning');
        } else {
          let state = this.estados.filter(s => s.uf === result.uf)[0];

          this.enderecoForm.get('rua').setValue(result.logradouro);
          this.enderecoForm.get('bairro').setValue(result.bairro);
          this.enderecoForm.get('complemento').setValue(result.complemento);
          this.enderecoForm.get('cidade').setValue(result.localidade);
          this.enderecoForm.get('estado').setValue(state.name);
        }

      },
      (error: any) => {
        console.log(error);
      }
    )
  }

  async delete(usuarioId: number) {

    const confirmation = await this._appService.fireSwalAsync('Atenção', 'Deseja Realmente salvar?', 'Deletar');

    if (confirmation) {
      this._usuarioHttpService.delete(usuarioId).subscribe({
        next: (result) => {
          this._messageService.show('Atenção', 'Registro deletado com sucesso!', 'success');
          this.voltar();
        },
        error: (err) => {
          console.log(err);
          handleErrorMessage(this._messageService, err);
        }
      })
    }

  }

  /**
  * get do formulário geral;
   */
  get f() {
    return this.form.controls;
  }

  /**
   * get do formulário endereço;
   */
  get enderecoForm() {
    return this.f.endereco;
  }

}
